import { Link } from "gatsby";
import React from "react";
import Seo from "../components/seo"
import { Card } from "react-bootstrap";
import AccordionItem from "../components/accordion-item";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import PortfolioFeed from "../components/portfolio-feed";
import Quote from "../components/quote";
import designBackground from "../images/backrounds/design-services.png";

const cardOptions = [
  {
    title: "IDENTITY MATTERS TO US",
    text: [
      <p>
        Brand identity is a big deal to us because our own brand identity is
        what helps us connect to our customers.
      </p>,
      <p>
        We’re smart, fun, trustworthy and creative. What are you? We’ll help you
        figure it out.
      </p>,
    ],
  },
  {
    title: "WE’RE TIMELESS",
    text: [
      <p>
        We’ve been a Bay Area tech innovator since 2003 and we bring decades of
        experience to your projects.
      </p>,
      <p>
        We know how to think beyond the moment’s biggest trends and create a
        timeless brand identity that serves your company for years to come.
      </p>,
    ],
  },
  {
    title: "BRANDING IS A BUILT-IN FEATURE",
    text: [
      <p>
        At 729 Solutions, we believe that branding must be built into the DNA of
        every project.
      </p>,
      <p>
        It should never be an afterthought. When you work with us, your brand
        will speak in a crystal clear voice to your customers.
      </p>,
    ],
  },
];

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Branding Design &amp; Logo design"
        description="As a branding design consulting firm, we have innovative brand identity and brand consultant experts ready to help you build engagement and make your visuals unforgettable."
      />
      <section className="mb-6">
        <div
          className="hero right mb-5"
          style={{
            backgroundImage: `url(${designBackground})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">Brand identity consultant services</h1>
            <p className="text-white">
              The best brands in the world are so memorable, people recognize
              them simply by the shape and{" "}
              <Link
                className="text-secondary-light"
                to="/cmyk-rgb-spot-colors/"
              >
                color of their logos.
              </Link>
            </p>
            <p className="text-white">
              <b>Great news:</b> We can help you create a brand identity that
              resonates on a deep level with your customers and helps them feel
              fully engaged with your company.
            </p>

            <CTA
              href="/contact-us/"
              text="Tell Us About Your Project"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row py-6 my-6">
            <div className="col-12">
              <h2>
                Your brand identity is everything. Let's make it unforgettable.
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary my-6 py-6">
        <div className="container">
          <div className="row text-white mb-4">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <h2 className="text-white mb-2">
                A brand identity consultant is the guardian of your brand.
              </h2>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="row">
                <div className="col-3 p-0">
                  <svg
                    className="text-white"
                    id="b661909e-b54e-4323-9fdb-5e78056b0846"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="eceaab78-c5d9-4dee-aad1-01592ecd4a7b"
                      data-name="section-1"
                    >
                      <path
                        id="b5823c9e-3024-460a-b701-dec7c9d5aa9e"
                        data-name="Path 844"
                        d="M22.92,17.06v25.6h7.25L41.6,32H63.46v75.8L52.8,119.22v7.72H90.14v-7.72L78.4,107.79V32h23.48l11.43,10.67h7.77V17.06Z"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-3 order-lg-3 mb-3">
              <p>
                They manage the details of your logo, fonts, colors, and the
                look and feel of your brand from a customer’s point of view.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-4 order-lg-5 mb-3">
              <p>
                They use meaning to build a cohesive brand identity and visual
                strategy that extends across every message you share.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-5 order-lg-4 mb-3">
              <p>
                With a branding consultant’s help, there is never any ambiguity
                about how you should present your business visually.
              </p>
            </div>
          </div>
          <div className="row text-white mb-4">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <h2 className="text-white mb-2">
                Behind the scenes, there's brand research
              </h2>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="row">
                <div className="col-3 p-0">
                  <svg
                    id="aeed6b2d-d831-4b2d-8f14-3aaa656d2064"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="a9454c5c-5314-4c81-83b5-f246e6e24d6f"
                      data-name="section-2"
                    >
                      <circle
                        id="e83fff77-6bb7-4d11-9026-597d526c4407"
                        data-name="Ellipse 168"
                        cx="34.74"
                        cy="23"
                        r="12"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="fba15286-ae3a-4ccd-ac4c-be109a5e553d"
                        data-name="Path 842"
                        d="M60,22.56h67v77H64"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <line
                        id="e49815df-b251-41d1-b165-59fa754fb5f6"
                        data-name="Line 238"
                        x1="96.74"
                        y1="100"
                        x2="117.74"
                        y2="133"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="f4480299-edbb-4599-98fc-6c5383f3c086"
                        data-name="Path 843"
                        d="M88.87,44.56H29A11.86,11.86,0,0,0,17,56.27v69.29c-.07,3.34,1.68,5.71,5,6,1.68.09,3.78.16,5-1a7.53,7.53,0,0,0,2-5v-34H39v34c-.07,3.34,1.68,5.71,5,6,1.68.09,3.78.16,5-1a7.53,7.53,0,0,0,2-5v-69l38-2a5.39,5.39,0,0,0,5-5c.15-1.72-1.47-2.06-2.52-3.66A3.09,3.09,0,0,0,88.87,44.56Z"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <line
                        id="adae36ce-9e09-4268-adec-f360d4b23960"
                        data-name="Line 239"
                        x1="77"
                        y1="22.56"
                        x2="77"
                        y2="12.56"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-3 order-lg-3 mb-3">
              <p>
                <b>We’ll let you in on a little secret:</b> Brand research is
                the secret to making the right decisions about your branding
                efforts.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-4 order-lg-4 mb-3">
              <p>
                Brand research illuminates your market position and creates a
                picture of how people view your brand. That helps you capture a
                larger slice of the competitive pie.
              </p>
            </div>
          </div>
          <div className="row text-white mb-4">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <h2 className="text-white mb-2">
                Brand strategy consulting takes your brand to a new level
              </h2>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="row">
                <div className="col-3 p-0">
                  <svg
                    id="f77799d2-1dba-4e38-a2c0-73ed98d366b1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="ec96a2a4-b81d-4597-8afb-4069ad9dcc39"
                      data-name="section-3"
                    >
                      <circle
                        id="afc651e2-f475-4d3a-944d-a3f08921cb2f"
                        data-name="Ellipse 66"
                        cx="65.9"
                        cy="24.75"
                        r="11.85"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="f5dd8dca-286a-4168-852c-b6f495deb9d4"
                        data-name="Path 431"
                        d="M77.74,68.21v-8l10.63,6.67a5.87,5.87,0,0,0,7-.52l14.29-12.31a5.24,5.24,0,0,0,.56-7.35h0a5.16,5.16,0,0,0-6.9-.87L91,54.53,78.91,47a5.93,5.93,0,0,0-3.15-.91H56.14a5.86,5.86,0,0,0-3.68,1.3L34.78,61.18a5.94,5.94,0,0,0-2.21,4.63V86.38a5.13,5.13,0,0,0,5.25,5h0a5.16,5.16,0,0,0,5.24-4.57L44.65,69.2l10.06-6.61v19a7.9,7.9,0,0,0,7.9,7.9h17.8l4.74,15.08a6.1,6.1,0,0,0,7.45,3.88,6,6,0,0,0,4-7.49L90.68,82.05A6,6,0,0,0,84.9,78H69.77"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="f1d87341-abf6-4dfb-85b6-3f3e30dce372"
                        data-name="Path 432"
                        d="M106.92,131.1H76.22l1.92-11.52H105Z"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="e4d7231d-987c-49af-9c76-2bbb49708a26"
                        data-name="Path 433"
                        d="M68.26,100.48l-.41,5.76a5.86,5.86,0,0,1-.72,2.34l-9.87,17.77a5.92,5.92,0,1,1-10.35-5.76l9.26-16.66.4-4.47"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-3 order-lg-3 mb-3">
              <p>
                Brand identity consulting helps you create a cohesive visual
                strategy that gives consistency across all messages and
                platforms you use.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-4 order-lg-4 mb-3">
              <p>
                When your clients and prospects encounter your content, they’ll
                immediately understand your brand identity. This cohesive
                messaging helps you build trust and loyalty.
              </p>
            </div>
          </div>
          <div className="row text-white mb-4">
            <div className="col-12 col-lg-6 order-2 order-lg-1">
              <h2 className="text-white mb-2">
                You need an all-in-one branding solution – that's us
              </h2>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              <div className="row">
                <div className="col-3 p-0">
                  <svg
                    id="a9567ac2-25b8-4e1c-9f96-f242f01d449e"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 144 144"
                  >
                    <g
                      id="bd5313f1-81c5-4b21-a40b-950d303d812f"
                      data-name="section-4"
                    >
                      <path
                        id="a96a821c-9654-4c5f-8b26-736359bda3eb"
                        data-name="Path 858"
                        d="M29.3,79.25v47h86v-47"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="afab15f1-ddda-4d72-9d56-75f6ec037a28"
                        data-name="Path 859"
                        d="M130.25,52.27a17.5,17.5,0,0,1-17.4,15.56,17.87,17.87,0,0,1-13.61-6.61,17.43,17.43,0,0,1-13.62,6.61A17.81,17.81,0,0,1,72,61.22a17.81,17.81,0,0,1-13.62,6.61,17.43,17.43,0,0,1-13.62-6.61,18.16,18.16,0,0,1-13.61,6.61,17.5,17.5,0,0,1-17.4-15.57L37,17.25h70Z"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                      <path
                        id="fe1ae6e2-a8fb-4df1-b23c-bed3b4b85e39"
                        data-name="Path 860"
                        d="M60.3,126.75V95.25h23v31.5"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-3 order-lg-3 mb-3">
              <p>
                We provide complete brand consulting, research and identity
                services as an all-in-one package.
              </p>
            </div>
            <div className="col-12 col-lg-6 order-4 order-lg-4 mb-3">
              <p>
                Our approach is comprehensive because the best branding
                strategies are themselves comprehensive, nourishing your
                relationship with every customer and helping them bond with your
                brand on multiple levels.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <CTA
                href="/contact-us/"
                text="Tell us about your Branding Project"
                className="btn btn-secondary-light"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>
                Here's why we're your best choice for brand identity consulting
              </h2>
            </div>
          </div>
          <div className="row">
            {cardOptions.map((card) => (
              <div className="col-12 col-lg-4">
                <div className="p-3">
                  <Card className="card custom-cards mb-5 py-4">
                    <Card.Body>
                      <Card.Title as="h3">{card.title}</Card.Title>
                      <Card.Text>{card.text}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-dark py-6">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="text-secondary-light">
                What Our Clients Say About us
              </h2>
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                dark
                quote="The design team did an awesome job defining granular user personas and created the foundation for the look and feel of our brand."
                name="Eric Major"
                company="Bundle Loans"
              />
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                dark
                quote="They do a good job of setting the right expectations. The fact that we are coming back time and again is a testimony!!"
                name="Stuart Harris"
                company="AltaFoodcraft"
              />
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                dark
                quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed.!"
                name="Kayla Fischer"
                company="Hirevue"
              />
            </div>
          </div>
        </div>
      </section>
      <PortfolioFeed className="my-6" />
      <section>
        <div className="container mb-6">
          <div className="row pt-6">
            <div className="col-12">
              <h2>Have questions about branding? Here are some faqs.</h2>
            </div>
            <div className="col-12">
              <div className="accordion-729">
                <AccordionItem
                  checked
                  id="0"
                  title="WHAT IS A BRAND CONSULTANT"
                  text="A brand consultant works in partnership with your in-house staff to analyze and solve the most difficult and important identity challenges you have. With their expertise, your brand identity becomes more consistent and powerful, helping you connect with prospects and reach your business goals."
                />
                <AccordionItem
                  id="1"
                  title="WHAT IS BRAND STRATEGY CONSULTING?"
                  text="Through brand strategy consulting, you and your consultant will do an analysis of your brand performance, identifying key areas for improvement that will clarify your brand identity. Every detail of your brand is scrutinized, helping illuminate the best path forward to a stronger brand identity."
                />
                <AccordionItem
                  id="2"
                  title="WHAT IS A BRANDING SPECIALIST"
                  text="A branding specialist is part of the team that preserves and promotes your brand identity. They specialize in managing all the details, like colors, logos, fonts and specs that are then applied to your mobile app, website, software and more for a cohesive look and feel."
                />
                <AccordionItem
                  id="3"
                  title="WHY SHOULD I HIRE A BRANDING CONSULTANT?"
                  text="When you hire a branding consultant, you join the ranks of world-class companies that use these consultants to develop and maintain flawless, powerful identities that help them build trust and sell without being pushy.Your branding consultant helps you save time, money and other resources so you can focus on your core competencies while they focus on your branding identity challenges."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary py-6 mb-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <h2 className="text-white">
                    Want to speak with us about your brand identity project?
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-5 mb-lg-0">
              <p className="text-white">
                We love a challenge. Bring us your toughest problems and we’ll
                help you sort out the pieces!
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <CTA
                href="/contact-us/"
                text="Tell Us About Your Brand Identity Project"
                className="btn btn-secondary-light"
              />
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};

export default Index;
